export namespace Paths {
  export const offers = '/offers';
  export const requests = '/requests';
  export const auth = '/auth';

  export namespace Offer {
    export const create = `${offers}/create`;
    export const edit = (id: string) => `${offers}/${id}`;
  }

  export namespace Request {
    export const create = `${requests}/create`;
    export const edit = (id: string) => `${requests}/${id}`;
  }

  export namespace Auth {
    export const login = `${auth}/login`;
    export const confirmEmail = `${auth}/confirm-email`;
    export const register = `${auth}/register`;
    export const requestPasswordChange = `${auth}/request-password-change`;
    export const changePassword = `${auth}/change-password`;
  }
}