import React, {useState} from 'react';
import {Formik, FormikErrors} from 'formik';
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {post} from "../../lib/requests";
import {ApiUrls} from "../../dtos/urls";
import {useHistory} from "react-router-dom";
import {Paths} from "../../dtos/paths";
import DialogButton from "../../components/forms/DialogButton";
import {
  RequestPasswordChangeDto,
  RequestPasswordChangeResponseDto,
  RequestPasswordChangeStatus
} from "../../dtos/auth/request-password-change.dto";
import {Helmet} from "react-helmet";

const isEmail = require('is-email');

interface Values {
  email: string;
}

const RequestPasswordChangePage = () => {
  const initialValues: Values = {
    email: '',
  };

  const [status, setStatus] = useState<RequestPasswordChangeStatus | null>(null);
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Request password reset</title>
      </Helmet>

      <Formik
        initialValues={initialValues}

        validate={(values) => {
          const errors: FormikErrors<Values> = {};

          if(!isEmail(values.email)) {
            errors.email = 'Should be a valid email';
          }

          return errors;
        }}

        onSubmit={async (values, {setSubmitting}) => {
          setSubmitting(true);

          try {
            const response = await post<
              RequestPasswordChangeDto,
              RequestPasswordChangeResponseDto
            >(ApiUrls.Auth.requestPasswordChange, {
              email: values.email,
            });

            setStatus(response.status);

          } catch(e) {
            setStatus(RequestPasswordChangeStatus.SendingError);

          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
            values,
            errors,
            setValues,
            submitForm,
            resetForm,
            isSubmitting,
          }) =>
          <Dialog open={true}>
            { status === null &&
             <>
                <DialogTitle>
                    Resetting the password for your account
                </DialogTitle>

                <DialogContent>
                    <Stack>
                        <TextField label="Email"
                                   autoComplete="email"
                                   placeholder="Enter your email address"
                                   value={values.email}
                                   error={!!errors.email}
                                   onChange={event =>
                                     setValues({...values, email: event.target.value})}
                                   helperText={errors.email}
                                   fullWidth
                                   sx={{marginTop: 1, marginBottom: 1}}/>

                    </Stack>
                </DialogContent>

                <DialogActions>
                  <DialogButton text="Back"
                                color="secondary"
                                onClick={() => history.goBack()}
                  />

                  <DialogButton text="Reset password"
                                loading={isSubmitting}
                                onClick={submitForm}
                  />
                </DialogActions>
              </>
            }

            { status === RequestPasswordChangeStatus.Ok &&
            <DialogContent>
                <Typography>
                    The link for resetting your password has been sent to your email address.
                    Please check your email to continue the password resetting process.
                </Typography>
            </DialogContent>
            }

            { status === RequestPasswordChangeStatus.AlreadySent &&
              <>
                <DialogContent>
                  <Typography>
                      The registration link has already been sent to your email.
                      Please, wait for a while and recheck your email and check your spam folder.
                      If you want to try again, please choose the "Send again" option below.
                  </Typography>
                </DialogContent>

                <DialogActions>
                  <DialogButton text="Send again"
                                loading={isSubmitting}
                                onClick={submitForm}
                  />
                </DialogActions>
              </>
            }

            { status === RequestPasswordChangeStatus.NotRegistered &&
            <>
                <DialogContent>
                    <Typography>
                        Sorry, we don't have a registered account with this email address.
                        Please register or choose another email.
                    </Typography>
                </DialogContent>

                <DialogActions>
                  <DialogButton text="Back"
                                color="secondary"
                                onClick={() => {
                                  resetForm();
                                  setStatus(null);
                                }}
                  />

                  <DialogButton text="Reset your password"
                                onClick={() => history.push(Paths.Auth.requestPasswordChange)}
                  />
                </DialogActions>
            </>
            }
          </Dialog>
        }
      </Formik>
    </>
  )
}

export default RequestPasswordChangePage;
