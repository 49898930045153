import { IsDateString, IsIn, Min } from 'class-validator';

export enum ContainerType {
  _20ft = '20ft',
  _40ft = '40ft',
}

export class CreateOfferDto {
  @Min(1)
  fromPortId = 0;

  @Min(1)
  toPortId = 0;

  pickPoint = '';

  @IsDateString()
  pickStart = '';

  @IsDateString()
  pickEnd = '';

  dropPoint = '';

  @IsDateString()
  dropStart = '';

  @IsDateString()
  dropEnd = '';

  @IsIn([ContainerType._20ft, ContainerType._40ft])
  containerType = '';

  @Min(0)
  euroPalletsNum = 0;

  @Min(0)
  finPalletsNum = 0;

  @Min(0)
  maxWeight = 0;

  @Min(0)
  price = 0;
}
