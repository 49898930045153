import { IsNotEmpty } from 'class-validator';

export class ChangePasswordDto {
  @IsNotEmpty()
  token = '';

  @IsNotEmpty()
  newPassword = '';
}

export enum ChangePasswordStatus {
  Ok = 'ok',
  BadToken = 'bad-token',
}
