import React, { useEffect, useState } from 'react';
import OfferRequestForm from '../../components/forms/OfferRequestForm';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { GetOneRequestDto } from '../../dtos/requests/get-one-request.dto';
import { ApiUrls } from '../../dtos/urls';
import { get } from '../../lib/requests';

const EditRequestPage = () => {
  const { id } = useParams<{id?: string}>();
  const [request, setRequest] = useState<GetOneRequestDto | undefined>(undefined);

  useEffect(() => {
    get<GetOneRequestDto>(ApiUrls.request(id ?? 0)).then(r => setRequest(r));
  }, []);

  return <>
    <Helmet>
      <title>Edit request</title>
    </Helmet>

    {request && <OfferRequestForm kind="request" request={request}/>}
  </>
}

export default EditRequestPage;
