import {Checkbox} from "@mui/material";
import React from "react";

interface LabeledCheckboxProps {
  label: React.ReactNode;
  error?: boolean
  value: boolean
  onChange: (newValue: boolean) => void
}

const LabeledCheckbox = (props: LabeledCheckboxProps) => {
  const errorStyle = (props.error ? {color: 'red'} : {});

  return (
    <div style={{display: 'flex'}}>
      <div style={{minWidth: 45}}>
        <Checkbox checked={props.value}
                  onChange={event => props.onChange(event.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }} />
      </div>
      <div style={{marginTop: 9.5, ...errorStyle}}>
        { props.label }
      </div>
    </div>
  )
}

export default LabeledCheckbox;