import useSWR, { SWRConfiguration } from 'swr';
import { prependApiPrefix } from '../dtos/urls';
import {credentials, handleUnauthorized} from './requests';

const fetcher = (url: string) => fetch(url, credentials).then((res) => {
  handleUnauthorized(res);
  return res.json()
});

export const useRequest = <Data = any, Error = any>(url: string, config?: SWRConfiguration) => {
  return useSWR<Data, Error>(prependApiPrefix(url), fetcher, config);
}