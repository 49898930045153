import {GetManyMatchedOfferDto} from "../../dtos/requests/get-many-request.dto";
import {useReadyToTalk} from "./useReadyToTalk";
import {MatchedCaption} from "./MatchedCaption";
import {Box, Grid, IconButton, Link, Switch} from "@mui/material";
import React from "react";
import {Match} from "./Match";
import {daysBefore, matchExpanded} from "./utils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {copyToClipboard} from "../../lib/clipboard";

export const MatchedOffer = (props: {
  match: GetManyMatchedOfferDto,
  index: number,
}) => {
  const {match} = props;
  const {ready, email, tryChange} = useReadyToTalk(match, 'request');

  const palletsNum = match.offer.euroPalletsNum + match.offer.finPalletsNum;

  return (
    <Match
      key={match.matchId}
      caption={
        <MatchedCaption
          index={props.index}
          palletsNum={palletsNum}
          match={match}
        />
      }
      details={
        <>
          <Line
            name={<>Company:</>}
            value={<b>{match.offer.company.name}</b>}
          />
          <Line
            name={<>Days before shipping:</>}
            value={<b>{daysBefore(new Date(match.offer.pickEnd))}</b>}
          />
          <Line
            name={<>Average price ($/pallet):</>}
            value={<b>{Math.round(match.offer.price / palletsNum)}</b>}
          />
          <Line
            name={
              <>
                <Box>Ready to talk:</Box>
                <Box><i>(your email will be shared)</i></Box>
              </>
            }
            value={
              <Switch
                checked={ready}
                onChange={e => tryChange(e.currentTarget.checked)}
              />
            }
          />

          {email &&
            <Line
              name={<>Mail to:</>}
              value={
                <>
                  <Link href={`mailto:${email}`}>{email}</Link>
                  <IconButton
                    sx={{marginLeft: '5px'}}
                    onClick={() => copyToClipboard(email).then(null)}
                  >
                    <ContentCopyIcon/>
                  </IconButton>
                </>
              }
            />
          }
        </>
      }
      expanded={matchExpanded(match)}
    />
  );
};

export const Line = (props: {
  name: JSX.Element,
  value: JSX.Element,
}) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        {props.name}
      </Grid>
      <Grid item>
        {props.value}
      </Grid>
    </Grid>
  );
};