import {CardContent, Collapse, Divider, Grid, IconButton, IconButtonProps, styled} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const Match = (props: {
  caption: JSX.Element,
  details: JSX.Element,
  expanded?: boolean,
}) => {
  const [expanded, setExpanded] = useState(!!props.expanded);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const {current} = contentRef;
    if(props.expanded && current) {
      current.scrollIntoView(true);
    }
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Divider/>
      <CardContent ref={contentRef} >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            {props.caption}
          </Grid>
          <Grid item>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={expanded}>
        <CardContent
          sx={{
            paddingTop: 0,
          }}
        >
          {props.details}
        </CardContent>
      </Collapse>
    </>
  )
}

