import React, {FC, useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import {Box, AppBar, Toolbar, Typography, Grid, IconButton, Menu, ListItemIcon, MenuItem} from '@mui/material';
import { Paths } from './dtos/paths';
import OffersPage from './pages/offers/OffersPage';
import CreateOfferPage from './pages/offers/CreateOfferPage';
import EditOfferPage from './pages/offers/EditOfferPage';
import RequestsPage from './pages/requests/RequestsPage';
import CreateRequestPage from './pages/requests/CreateRequestPage';
import EditRequestPage from './pages/requests/EditRequestPage';
import ConfirmEmailPage from './pages/auth/ConfirmEmailPage';
import RegisterPage from './pages/auth/RegisterPage';
import RequestPasswordChangePage from './pages/auth/RequestPasswordChangePage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import LoginPage from "./pages/auth/LoginPage";
import {post, setUnauthorizedHandler} from "./lib/requests";
import {useRequest} from "./lib/useRequest";
import {ApiUrls} from "./dtos/urls";
import {GetCompanyProfileDto} from "./dtos/companies/get-company-profile.dto";
import {AccountCircle, Logout} from "@mui/icons-material";


const App = () => {
  return <>
    <CssBaseline />

    <BrowserRouter>
      <Routes/>
    </BrowserRouter>
  </>
}

const Routes = () => {
  const history = useHistory();

  useEffect(() => {
    setUnauthorizedHandler(() => history.push(Paths.Auth.login));
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={Paths.offers}/>
      </Route>

      <Route path={Paths.Offer.create}>
        <MainLayout>
          <CreateOfferPage/>
        </MainLayout>
      </Route>
      <Route path={Paths.Offer.edit(':id')}>
        <MainLayout>
          <EditOfferPage/>
        </MainLayout>
      </Route>
      <Route path={Paths.offers}>
        <MainLayout>
          <OffersPage/>
        </MainLayout>
      </Route>

      <Route path={Paths.Request.create}>
        <MainLayout>
          <CreateRequestPage/>
        </MainLayout>
      </Route>
      <Route path={Paths.Request.edit(':id')}>
        <MainLayout>
          <EditRequestPage/>
        </MainLayout>
      </Route>
      <Route path={Paths.requests}>
        <MainLayout>
          <RequestsPage/>
        </MainLayout>
      </Route>

      <Route path={Paths.Auth.login}>
        <LoginPage/>
      </Route>
      <Route path={Paths.Auth.confirmEmail}>
        <ConfirmEmailPage/>
      </Route>
      <Route path={Paths.Auth.register}>
        <RegisterPage/>
      </Route>
      <Route path={Paths.Auth.requestPasswordChange}>
        <RequestPasswordChangePage/>
      </Route>
      <Route path={Paths.Auth.changePassword}>
        <ChangePasswordPage/>
      </Route>

    </Switch>
  )
}

const MainLayout: FC = (props) => {
  return (
    <>
      <TopBar/>
      <Box margin={2}>
        {props.children}
      </Box>
    </>
  )
}

const TopBar = () => {
  const history = useHistory();

  const {
    data: profile,
    mutate: mutateProfile
  } = useRequest<GetCompanyProfileDto>(ApiUrls.Auth.profile);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    handleClose();
    await post(ApiUrls.Auth.logout, {});
    await mutateProfile(undefined, true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{paddingRight: 1}}>
          <Typography variant="h6"
                      sx={{cursor: 'pointer'}}
                      onClick={() => history.push(Paths.offers)}>
            Offers
          </Typography>

          <Typography variant="h6"
                      sx={{
                        marginLeft: 2,
                        cursor: 'pointer',
                      }}
                      onClick={() => history.push(Paths.requests)}>
            Requests
          </Typography>

          { profile &&
            <Grid
              container
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Typography>
                  {profile.name}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClick}>
                  <AccountCircle fontSize="large" sx={{color: 'white'}}/>
                </IconButton>

                <Menu open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                >
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          }

        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default App;
