import React, { useCallback } from 'react';
import { useRequest } from '../../lib/useRequest';
import { ApiUrls, RequestId } from '../../dtos/urls';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../dtos/paths';
import { delete_ } from '../../lib/requests';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { GetManyRequestWithMatchesDto } from '../../dtos/requests/get-many-request.dto';
import OfferRequestCard from "../../components/offer-request-card/OfferRequestCard";
import '../pretty-print-json.css';


const RequestsPage = () => {

  const {
    data: requests,
    mutate: mutateRequests,
  } = useRequest<GetManyRequestWithMatchesDto[]>(ApiUrls.requests);

  const history = useHistory();

  const deleteRequest = useCallback(async (requestId: RequestId) => {
    if(window.confirm('Are you sure you want to delete request?')) {
      await delete_(ApiUrls.request(requestId));
      await mutateRequests(undefined, true);
    }
  }, [mutateRequests])

  return <>
    <Helmet>
      <title>Requests</title>
    </Helmet>

    {requests &&
      <>
        <Grid container justifyContent={requests?.length ? "flex-end" : "flex-start"}>
          <Grid item>
            <Button onClick={() => history.push(Paths.Request.create)}
                    variant="contained"
                    sx={{marginBottom: 2}}
            >Create request</Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {requests?.map((request, index) =>
            <Grid item xs={12} md={6} lg={4} xl={3} key={request.id}>
              <OfferRequestCard {...{request}}
                                onEdit={() => history.push(Paths.Request.edit(request.id.toString()))}
                                onDelete={() => deleteRequest(request.id)}
              />
            </Grid>
          )}
        </Grid>
      </>
    }

    {!requests && <>Loading...</>}
  </>
}

export default RequestsPage;
