import {GetManyMatchedOfferRequestDto} from "../../dtos/offers/get-many-offer-request.dto";

export const daysBefore = (date: Date): number => {
  let delta = date.getTime() - Date.now();

  if(delta < 0) {
    delta = 0;
  }

  return Math.floor(delta / (3600*24*1000));
}

export const matchExpanded = (match: GetManyMatchedOfferRequestDto): boolean => {
  const url = new URL(window.location.href);
  return url.searchParams.get('match') === match.matchId.toString();
}