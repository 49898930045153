import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useRequest } from '../../lib/useRequest';
import { ApiUrls, OfferId } from '../../dtos/urls';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { Paths } from '../../dtos/paths';
import { GetManyOfferWithMatchesDto } from '../../dtos/offers/get-many-offer.dto';
import { delete_ } from '../../lib/requests';
import { Helmet } from 'react-helmet';
import OfferRequestCard from "../../components/offer-request-card/OfferRequestCard";
import '../pretty-print-json.css';

const OffersPage = () => {
  const {
    data: offers,
    mutate: mutateOffers,
  } = useRequest<GetManyOfferWithMatchesDto[]>(ApiUrls.offers);

  const history = useHistory();

  const deleteOffer = useCallback(async (offerId: OfferId) => {
    if(window.confirm('Are you sure you want to delete offer?')) {
      await delete_(ApiUrls.offer(offerId));
      await mutateOffers(undefined, true);
    }
  }, [mutateOffers])

  console.log(offers);

  return <>
    <Helmet>
      <title>Offers</title>
    </Helmet>

    {offers &&
      <>
        <Grid container justifyContent={offers?.length ? "flex-end" : "flex-start"}>
          <Grid item>
            <Button onClick={() => history.push(Paths.Offer.create)}
                    variant="contained"
                    sx={{marginBottom: 2}}
            >Create offer</Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {offers?.map((offer, index) =>
            <Grid item xs={12} md={6} lg={4} xl={3} key={offer.id}>
              <OfferRequestCard {...{offer}}
                                onEdit={() => history.push(Paths.Offer.edit(offer.id.toString()))}
                                onDelete={() => deleteOffer(offer.id)}
              />
            </Grid>
          )}
        </Grid>
      </>
    }

    {!offers && <>Loading...</>}
  </>
}

export default OffersPage;