import { IsOptional } from 'class-validator';

export class UpdateOfferDto {
  @IsOptional()
  fromPortId?: number;

  @IsOptional()
  toPortId?: number;

  @IsOptional()
  pickPoint?: string;

  @IsOptional()
  pickStart?: string;

  @IsOptional()
  pickEnd?: string;

  @IsOptional()
  dropPoint?: string;

  @IsOptional()
  dropStart?: string;

  @IsOptional()
  dropEnd?: string;

  @IsOptional()
  containerType?: string;

  @IsOptional()
  euroPalletsNum?: number;

  @IsOptional()
  finPalletsNum?: number;

  @IsOptional()
  maxWeight?: number;

  @IsOptional()
  price?: number;
}
