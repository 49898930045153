import React, {useState} from 'react';
import {Formik, FormikErrors} from 'formik';
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {post} from "../../lib/requests";
import {ApiUrls} from "../../dtos/urls";
import {ConfirmEmailDto, ConfirmEmailResponseDto, ConfirmEmailStatus} from "../../dtos/auth/confirm-email.dto";
import {useHistory} from "react-router-dom";
import {Paths} from "../../dtos/paths";
import LabeledCheckbox from "../../components/forms/fields/LabeledCheckbox";
import DialogButton from "../../components/forms/DialogButton";
import {Helmet} from "react-helmet";

const isEmail = require('is-email');

interface Values {
  email: string;
  allowUseAsLogin: boolean;
  allowGetEmail: boolean;
}

const ConfirmEmailPage = () => {
  const initialValues: Values = {
    email: '',
    allowUseAsLogin: false,
    allowGetEmail: false,
  };

  const [status, setStatus] = useState<ConfirmEmailStatus | null>(null);
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Your Email</title>
      </Helmet>

      <Formik
        initialValues={initialValues}

        validate={(values) => {
          const errors: FormikErrors<Values> = {};

          if (!isEmail(values.email)) {
            errors.email = 'Should be a valid email';
          }

          if (!values.allowUseAsLogin) {
            errors.allowUseAsLogin = 'Required';
          }

          if (!values.allowGetEmail) {
            errors.allowGetEmail = 'Required';
          }

          return errors;
        }}

        onSubmit={async (values, {setSubmitting}) => {
          setSubmitting(true);

          try {
            const response = await post<ConfirmEmailDto, ConfirmEmailResponseDto>(
              ApiUrls.Auth.confirmEmail, {
                email: values.email,
              });
            setStatus(response.status);

          } catch (e) {
            setStatus(ConfirmEmailStatus.SendingError);

          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
            values,
            errors,
            setValues,
            submitForm,
            resetForm,
            isSubmitting,
          }) =>
          <Dialog open={true}>
            {status === null &&
            <>
              <DialogTitle>
                Your Email
              </DialogTitle>

              <DialogContent>
                <Stack>
                  <TextField label="Email"
                             autoComplete="email"
                             placeholder="Enter your email address"
                             value={values.email}
                             error={!!errors.email}
                             onChange={event =>
                               setValues({...values, email: event.target.value})}
                             helperText={errors.email}
                             fullWidth
                             sx={{marginTop: 1, marginBottom: 1}}/>

                  <LabeledCheckbox label="I agree to use this email as a login for my account."
                                   value={values.allowUseAsLogin}
                                   error={!!errors.allowUseAsLogin}
                                   onChange={(newValue) => setValues({...values, allowUseAsLogin: newValue})}/>

                  <LabeledCheckbox
                    label="I agree to get an email from Flexyfreight.com with the registration link."
                    value={values.allowGetEmail}
                    error={!!errors.allowGetEmail}
                    onChange={(newValue) => setValues({...values, allowGetEmail: newValue})}/>

                </Stack>
              </DialogContent>

              <DialogActions>
                <DialogButton text="Back"
                              color="secondary"
                              onClick={() => history.goBack()}
                />

                <DialogButton text="Continue"
                              loading={isSubmitting}
                              onClick={submitForm}
                />
              </DialogActions>
            </>
            }

            {status === ConfirmEmailStatus.Ok &&
            <DialogContent>
              <Typography>
                The registration link has been sent to your email address.
                Please check your email to continue registration process.
              </Typography>
            </DialogContent>
            }

            {status === ConfirmEmailStatus.SendingError &&
            <>
              <DialogContent>
                <Typography>
                  Sorry, something goes wrong. We are working on the problem. Please, try again later.
                </Typography>
              </DialogContent>

              <DialogActions>
                <DialogButton text="Back"
                              color="secondary"
                              onClick={() => {
                                setStatus(null);
                              }}/>

                <DialogButton text="Send again"
                              loading={isSubmitting}
                              onClick={submitForm}/>
              </DialogActions>
            </>
            }

            {status === ConfirmEmailStatus.AlreadySent &&
            <>
              <DialogContent>
                <Typography>
                  The registration link has already been sent to your email.
                  Please, wait for a while and recheck your email and check your spam subfolder.
                  If you want to try again, please choose the "Send again" option below.
                </Typography>
              </DialogContent>

              <DialogActions>
                <DialogButton text="Send again"
                              loading={isSubmitting}
                              onClick={submitForm}/>
              </DialogActions>
            </>
            }

            {status === ConfirmEmailStatus.AlreadyRegistered &&
            <>
              <DialogContent>
                <Typography>
                  Sorry, we already have a registered account with this email address.
                  If you forget your password, please choose "Reset your password".
                </Typography>
              </DialogContent>

              <DialogActions>
                <DialogButton text="Back"
                              color="secondary"
                              onClick={() => {
                                resetForm();
                                setStatus(null);
                              }}/>

                <DialogButton text="Reset your password"
                              onClick={() => {
                                history.push(Paths.Auth.requestPasswordChange)
                              }}/>
              </DialogActions>
            </>
            }
          </Dialog>
        }
      </Formik>
    </>
  )
}

export default ConfirmEmailPage;
