import React from 'react';
import { LoadingButton } from "@mui/lab";

interface DialogButtonProps {
  text: string;
  loading?: boolean;
  color?: 'primary' | 'secondary';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const DialogButton = (props: DialogButtonProps) => {
  return (
    <LoadingButton
      variant="contained"
      loading={props.loading}
      color={props.color ?? 'primary'}
      onClick={props.onClick}
      sx={{
        marginBottom: 2,
        marginRight: 2
      }}
    >
      {props.text}
    </LoadingButton>
  )
}

export default DialogButton;