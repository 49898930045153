import React from 'react';
import { Helmet } from 'react-helmet';
import OfferRequestForm from '../../components/forms/OfferRequestForm';

const CreateOfferPage = () => {

  return <>
    <Helmet>
      <title>Create offer</title>
    </Helmet>

    <OfferRequestForm kind="offer"/>
  </>
}

export default CreateOfferPage;
