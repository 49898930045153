import {GetManyMatchedOfferRequestDto} from "../../dtos/offers/get-many-offer-request.dto";
import {useEffect, useMemo, useState} from "react";
import {patch} from "../../lib/requests";
import {MarkMatchReadyDto, MarkMatchReadyResponseDto} from "../../dtos/matches/mark-match-ready.dto";
import {ApiUrls} from "../../dtos/urls";

export const useReadyToTalk = (match: GetManyMatchedOfferRequestDto, kind: 'offer' | 'request') => {
  const [ready, setReady] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const memo = useMemo(() => ({
    locked: false,
    checked: false,
  }), []);

  useEffect(() => {
    setReady(kind === 'request' ? match.requestReady : match.offerReady);
  }, [match, kind]);

  useEffect(() => {
    setEmail(match.email);
  }, [match]);

  const tryChange = (checked: boolean) => {
    if(memo.locked) {
      return;
    }

    memo.locked = true;
    memo.checked = checked;

    patch<
      MarkMatchReadyDto,
      MarkMatchReadyResponseDto
      >(ApiUrls.match(match.matchId), {
      kind,
      ready: checked,
    }).then(res => {
      setReady(memo.checked);
      setEmail(res.email);
      memo.locked = false;
    }).catch(() => {
      memo.locked = false;
    });
  };

  return {
    ready,
    email,
    tryChange,
  }
}

