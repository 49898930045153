import React, {useEffect, useState} from "react";
import {GetManyCountryDto} from "../../../dtos/companies/get-many-country.dto";
import {ApiUrls} from "../../../dtos/urls";
import {get} from "../../../lib/requests";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface CountryAutocompleteProps {
  value: number;
  label: string;
  error?: string;
  fullWidth?: boolean;
  onChange?: (newValue: number) => void;
}

const CountryAutocomplete = (props: CountryAutocompleteProps) => {
  const [value, setValue] = useState<GetManyCountryDto | null>(null);
  const [options, setOptions] = useState<GetManyCountryDto[]>([]);

  useEffect(() => {
    get<GetManyCountryDto[]>(ApiUrls.countries)
      .then(countries => {
        setOptions(countries);
      });
  }, []);

  useEffect(() => {
    const country = options.find(country => country.id === props.value);
    if(country) {
      setValue(country);
    }
  }, [options, props.value]);

  return (
    <Autocomplete
      value={value}
      options={options}
      getOptionLabel={option => `${option.name}`}
      fullWidth={props.fullWidth}
      sx={{marginTop: 1, marginBottom: 1}}
      onChange={(event, value, reason, details) => {
        // console.log(`onChange: ${event} ${value} ${reason} ${details}`);
        props.onChange?.(value?.id ?? 0)
      }}
      renderInput={(params) => (
        <TextField {...params}
                   label={props.label}
                   error={!!props.error}
                   helperText={props.error}/>)}
    />
  )
}

export default CountryAutocomplete;