import React from "react";
import StarIcon from "@mui/icons-material/Star";
import {Grid, Typography} from "@mui/material";
import {GetManyMatchedOfferRequestDto} from "../../dtos/offers/get-many-offer-request.dto";

export const MatchedCaption = (props: {
  index: number,
  palletsNum?: number,
  totalPalletsNum?: number,
  match: GetManyMatchedOfferRequestDto,
}) => {
  const {index, palletsNum, totalPalletsNum, match} = props;
  const {offerReady, requestReady} = match;

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Typography>Match {index + 1}</Typography>
      </Grid>

      { palletsNum && totalPalletsNum &&
        <Grid item>
          <Typography sx={{fontWeight: 'bold'}}>
            (<b>{props.palletsNum}</b> matched pallets of {props.totalPalletsNum})
          </Typography>
        </Grid>
      }

      { (offerReady || requestReady) &&
        <Grid item>
          <StarIcon color={offerReady && requestReady ? 'success' : 'warning'}
                    sx={{marginTop: 0.6}} />
        </Grid>
      }
    </Grid>
  );
};
