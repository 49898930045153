import React from 'react';
import OfferRequestForm from '../../components/forms/OfferRequestForm';
import { Helmet } from 'react-helmet';

const CreateRequestPage = () => <>
  <Helmet>
    <title>Create request</title>
  </Helmet>

  <OfferRequestForm kind="request"/>
</>

export default CreateRequestPage;
