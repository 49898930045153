import React from "react";
import {
  Card,
  CardContent,
  CardHeader, Divider, Grid,
  IconButton, ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack, Typography
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import {GetManyOfferWithMatchesDto} from "../../dtos/offers/get-many-offer.dto";
import {GetManyRequestWithMatchesDto} from "../../dtos/requests/get-many-request.dto";
import {GetManyPortDto} from "../../dtos/ports/get-many-port.dto";
import {prettyPrintJson} from "pretty-print-json";
import {MatchedRequest} from "./MatchedRequest";
import {MatchedOffer} from "./MatchedOffer";
import {daysBefore} from "./utils";

export interface OfferRequestCardProps {
  offer?: GetManyOfferWithMatchesDto;
  request?: GetManyRequestWithMatchesDto;
  onEdit?: () => void;
  onDelete?: () => void;
}

const OfferRequestCard = (props: OfferRequestCardProps) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const closeMenu = () => setAnchorEl(null);

  const {offer, request} = props;

  const fromPort = offer?.fromPort ?? request?.fromPort ?? new GetManyPortDto();
  const toPort   = offer?.toPort   ?? request?.toPort   ?? new GetManyPortDto();

  //
  let palletsNum = 0;

  if(offer) {
    palletsNum = offer.euroPalletsNum + offer.finPalletsNum;
  } else if(request) {
    palletsNum = request.euroPalletsNum + request.finPalletsNum;
  }

  //
  const totalWeight = offer?.maxWeight      ?? request?.maxWeight ?? 0;
  const matchesNum  = offer?.matches.length ?? request?.matches.length ?? 0;

  const pickEnd = offer?.pickEnd ?? request?.pickEnd ?? (new Date().toJSON());

  request?.matches.forEach(match => {
    console.log(prettyPrintJson.toHtml(match));
  });

  //
  return (
    <Card elevation={3}>
      <CardHeader
        title={
          <Grid container columns={11} alignItems="center">
            <Grid item xs={5}>
              <Port port={fromPort}/>
            </Grid>
            <Grid item xs={1}>
              <Grid container justifyContent="center">
                <Grid item>
                  <DirectionsBoatIcon/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Port port={toPort}/>
            </Grid>
          </Grid>
        }

        action={<>
          <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={anchorEl !== null}
            onClose={closeMenu}
          >
            <MenuItem
              onClick={() => {
                closeMenu();
                props.onEdit?.();
              }}
            >
              <ListItemIcon><EditIcon/></ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                props.onDelete?.();
              }}
            >
              <ListItemIcon><DeleteIcon color="error"/></ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </>}
      />

      <Divider/>
      <CardContent>
        <Stack>
          <Stack>
            <Line caption="Days before shipping:"
                  value={daysBefore(new Date(pickEnd))}/>
            <Line caption="Number of pallets:"
                  value={palletsNum}/>
            <Line caption="Total weight, kg:" value={totalWeight}/>
            {offer && <Line caption="Price, USD:" value={offer.price}/>}
            <Line caption="Matches for review:" value={matchesNum}/>
          </Stack>
        </Stack>
      </CardContent>

      { offer?.matches.map((match, index) =>
        <MatchedRequest key={match.matchId} {...{match, index}} offerPalletsNum={palletsNum}/>
      )}

      { request?.matches.map((match, index) =>
        <MatchedOffer key={match.matchId} {...{match, index}} />
      )}
    </Card>
  )
}


const Port = (props: {port: GetManyPortDto}) => {
  const {port} = props;

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <PortName text={port.name}/>
      </Grid>
      <Grid item>
        <Typography sx={{fontWeight: "bold"}}>{port.locode}</Typography>
      </Grid>
      <Grid item>
        <PortName text={port.country}/>
      </Grid>
    </Grid>
  );
};

const PortName = (props: {text: string}) => {
  const maxLength = 20;
  let {text} = props;

  if(text.length > maxLength) {
    text = text.substring(0, maxLength).trimEnd() + '...';
  }

  return (
    <Typography sx={{ fontSize: 12}}>{text}</Typography>
  );
};

const Line = (props: {caption: string, value: number}) => {
  return (
    <Grid container>
      <Grid item xs={2}/>
      <Grid item xs={6}>
        <Typography>{props.caption}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{fontWeight: 'bold'}}>{props.value}</Typography>
      </Grid>
    </Grid>
  );
};

export default OfferRequestCard;