import React, {useEffect, useState} from 'react';
import {Formik, FormikErrors} from 'formik';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from "@mui/material";
import {get, post} from "../../lib/requests";
import {ApiUrls} from "../../dtos/urls";
import {useHistory} from "react-router-dom";
import {Paths} from "../../dtos/paths";
import {useQuery} from "../../lib/useQuery";
import {validatePassword} from "./RegisterPage";
import DialogButton from "../../components/forms/DialogButton";
import {ChangePasswordDto, ChangePasswordStatus} from "../../dtos/auth/change-password.dto";
import {Helmet} from "react-helmet";
import {PasswordChangeEmailDto} from "../../dtos/auth/request-password-change.dto";


interface Values {
  token: string;
  password: string;
  passwordCheck: string;
}

const ChangePasswordPage = () => {
  const query = useQuery();

  const initialValues: Values = {
    token: query.get('token') ?? '',
    password: '',
    passwordCheck: '',
  };

  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<ChangePasswordStatus | null>(null);
  const history = useHistory();

  useEffect(() => {
    get<PasswordChangeEmailDto>(ApiUrls.Auth.passwordChangeEmail, {
      params: {
        token: query.get('token'),
      }})
      .then(data => {
        setEmail(data.email);
      })
      .catch(() => {
        setStatus(ChangePasswordStatus.BadToken);
      })
  }, [query]);

  return (
    <>
      <Helmet>
        <title>Reset password</title>
      </Helmet>

      <Formik
        initialValues={initialValues}

        validate={(values) => {
          const errors: FormikErrors<Values> = {};
          validatePassword(values, errors);
          return errors;
        }}

        onSubmit={async (values, {setSubmitting}) => {
          setSubmitting(true);

          try {
            const {token, password} = values;
            await post<ChangePasswordDto>(ApiUrls.Auth.changePassword, {
              token,
              newPassword: password,
            });
            history.push(Paths.Auth.login);

          } catch(e) {

          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
            values,
            errors,
            setValues,
            submitForm,
            isSubmitting,
          }) =>
          <Dialog open={true}>
            { status === null &&
              <>
                <DialogTitle>
                  Please enter a new password for your account
                </DialogTitle>

                <DialogContent>
                  <Stack>
                    <TextField type="text"
                               value={email}
                               autoComplete="username"
                               style={{display: 'none'}}
                               inputProps={{ readOnly: true }}
                    />

                    <TextField label="Password"
                               type="password"
                               placeholder="Enter password"
                               autoComplete="new-password"
                               value={values.password}
                               error={!!errors.password}
                               onChange={event =>
                                 setValues({...values, password: event.target.value})}
                               helperText={errors.password}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <TextField label="Repeat password"
                               type="password"
                               placeholder="Repeat password"
                               autoComplete="new-password"
                               value={values.passwordCheck}
                               error={!!errors.passwordCheck}
                               onChange={event =>
                                 setValues({...values, passwordCheck: event.target.value})}
                               helperText={errors.passwordCheck}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>
                    </Stack>
                  </DialogContent>

                <DialogActions>
                  <DialogButton text="Continue"
                                loading={isSubmitting}
                                onClick={submitForm}
                  />
                </DialogActions>
              </>
            }
          </Dialog>
        }
      </Formik>
    </>
  )
}

export default ChangePasswordPage;
