import React from 'react';
import { ApiUrls } from '../../dtos/urls';
import {Field, Form, Formik, FormikErrors, FormikProps} from 'formik';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Grid from '@mui/material/Grid';
import PortAutocomplete from './fields/PortAutocomplete';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import Button from '@mui/material/Button';
import { ContainerType, CreateOfferDto } from '../../dtos/offers/create-offer.dto';
import { patch, post } from '../../lib/requests';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../dtos/paths';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select } from './formik-mui/Select';
import MenuItem from '@mui/material/MenuItem';
import { GetManyPortDto } from '../../dtos/ports/get-many-port.dto';
import { GetOneOfferDto } from '../../dtos/offers/get-one-offer.dto';
import { GetOneRequestDto } from '../../dtos/requests/get-one-request.dto';
import { UpdateOfferDto } from '../../dtos/offers/update-offer.dto';
import {Container, Divider} from '@mui/material';

interface OfferRequestFormProps {
  kind: 'offer' | 'request';
  offer?: GetOneOfferDto;
  request?: GetOneRequestDto;
}

const dateInputFormat = 'dd.MM.yyyy';

const OfferRequestForm = (props: OfferRequestFormProps) => {
  const history = useHistory();

  const edit = !!(props.offer || props.request);

  const parseDate = (dateString?: string): Date | null => {
    if(!dateString) {
      return null;
    }

    return new Date(dateString);
  }

  const initialValues: Values = {
    fromPort: props.offer?.fromPort ?? props.request?.fromPort,
    toPort:   props.offer?.toPort   ?? props.request?.toPort,

    dropPoint: props.offer?.dropPoint ?? props.request?.dropPoint ?? '',
    dropStart: parseDate(props.offer?.dropStart) ?? parseDate(props.request?.dropStart) ?? null,
    dropEnd:   parseDate(props.offer?.dropEnd)   ?? parseDate(props.request?.dropEnd)   ?? null,

    pickPoint: props.offer?.pickPoint ?? props.request?.pickPoint ?? '',
    pickStart: parseDate(props.offer?.pickStart) ?? parseDate(props.request?.pickStart) ?? null,
    pickEnd:   parseDate(props.offer?.pickEnd)   ?? parseDate(props.request?.pickEnd)   ?? null,

    containerType:   props.offer?.containerType  ?? props.request?.containerType  ?? ContainerType._20ft,
    finPalletsNum:  (props.offer?.finPalletsNum  || props.request?.finPalletsNum  || '').toString(),
    euroPalletsNum: (props.offer?.euroPalletsNum || props.request?.euroPalletsNum || '').toString(),
    maxWeight:      (props.offer?.maxWeight      ?? props.request?.maxWeight      ?? '').toString(),
    price:          (props.offer?.price                                           ?? '').toString(),
  };

  return (
    <Formik
      initialValues={initialValues}

      validate={(values: Values) => {
        const errors: FormikErrors<Values> = {};
        const required = 'The field is required';

        console.log(values);

        if(!values.fromPort) {
          errors.fromPort = required;
        }

        if(!values.toPort) {
          errors.toPort = required;
        }

        //
        if(!values.dropStart) {
          errors.dropStart = required;
        }

        if(!values.dropEnd) {
          errors.dropEnd = required;
        }

        if(values.dropStart && values.dropEnd && values.dropStart > values.dropEnd) {
          errors.dropStart = 'The drop-off start should be before its end';
          errors.dropEnd = 'The drop-off end should be after its start';
        }

        //
        if(!values.pickStart) {
          errors.pickStart = required;
        }

        if(!values.pickEnd) {
          errors.pickEnd = required;
        }

        if(values.pickStart && values.pickEnd && values.pickStart > values.pickEnd) {
          errors.pickStart = 'The pick-up start should be before its end';
          errors.pickEnd = 'The pick-up end should be after its start';
        }

        //
        let palletsTotal = 0;

        if(values.finPalletsNum) {
          const parsed = parseInt(values.finPalletsNum);

          if(isNaN(parsed) || parsed < 0) {
            errors.finPalletsNum = 'The number of pallets should be 0 or more';
          } else {
            palletsTotal += parsed;
          }
        }

        if(values.euroPalletsNum) {
          const parsed = parseInt(values.euroPalletsNum)

          if(isNaN(parsed) || parsed < 0) {
            errors.euroPalletsNum = 'The number of pallets should be 0 or more';
          } else {
            palletsTotal += parsed;
          }
        }

        if(!palletsTotal) {
          const error = 'A number of pallets should be specified';
          errors.finPalletsNum = error;
          errors.euroPalletsNum = error;
        }

        //
        if(values.maxWeight) {
          const parsed = parseInt(values.maxWeight);

          if(isNaN(parsed) || parsed < 1) {
            errors.maxWeight = 'The weight should be 1 kg or more';
          }

        } else {
          errors.maxWeight = required;
        }

        if(props.kind === 'offer') {
          if(values.price) {
            const parsed = parseFloat(values.price);

            if(isNaN(parsed) || parsed < 1) {
              errors.price = 'The price should be $1 or more';
            }
          } else {
            errors.price = required;
          }
        }

        return errors;
      }}

      onSubmit={async (values: Values) => {
        const {
          fromPort, toPort,

          dropPoint, dropStart, dropEnd,
          pickPoint, pickStart, pickEnd,

          containerType,
          euroPalletsNum,
          finPalletsNum,
          maxWeight,
          price,
        } = values;

        const toNumber = (s: string): number => {
          const parsed = parseInt(s);
          return isNaN(parsed) ? 0 : parsed;
        }

        if(edit) {
          const data = new UpdateOfferDto();

          const updated = <T,>(initialValue: T, value: T): T | undefined => {
            return initialValue !== value ? value : undefined;
          }

          const updatedDate = (initialValue: Date | null, value: Date | null): string | undefined => {
            return value && initialValue !== value ? value.toJSON() : undefined;
          }

          data.fromPortId = updated(initialValues.fromPort?.id ?? 0, fromPort?.id ?? 0);
          data.toPortId   = updated(initialValues.toPort?.id   ?? 0, toPort?.id   ?? 0);

          data.dropPoint = updated(initialValues.dropPoint, dropPoint);
          data.dropStart = updatedDate(initialValues.dropStart, dropStart);
          data.dropEnd   = updatedDate(initialValues.dropEnd,   dropEnd);

          data.pickPoint = updated(initialValues.pickPoint, pickPoint);
          data.pickStart = updatedDate(initialValues.pickStart, pickStart);
          data.pickEnd   = updatedDate(initialValues.pickEnd,   pickEnd);

          data.containerType = updated(initialValues.containerType, containerType);

          const updatedNumber = (initialValue: string, value: string): number | undefined => {
            const n = toNumber(value);
            return toNumber(initialValue) !== n ? n : undefined;
          }

          data.finPalletsNum  = updatedNumber(initialValues.finPalletsNum,  finPalletsNum);
          data.euroPalletsNum = updatedNumber(initialValues.euroPalletsNum, euroPalletsNum);
          data.maxWeight      = updatedNumber(initialValues.maxWeight,      maxWeight);
          data.price          = updatedNumber(initialValues.price,          price);

          try {
            switch(props.kind) {
              case 'offer': {
                const url = ApiUrls.offer(props.offer?.id ?? 0);
                await patch(url, data);
                history.push(Paths.offers);
                break;
              }
              case 'request': {
                const url = ApiUrls.request(props.request?.id ?? 0);
                await patch(url, data);
                history.push(Paths.requests);
                break;
              }
            }
          } catch(e) {
            alert(e);
          }

        } else {
          const data = new CreateOfferDto();

          data.fromPortId = fromPort?.id ?? 0;
          data.toPortId   = toPort?.id   ?? 0;

          data.dropPoint = dropPoint;
          data.dropStart = (dropStart ?? new Date()).toJSON();
          data.dropEnd = (dropEnd ?? new Date()).toJSON();

          data.pickPoint = pickPoint;
          data.pickStart = (pickStart ?? new Date()).toJSON();
          data.pickEnd = (pickEnd ?? new Date()).toJSON();

          data.containerType = containerType;

          data.finPalletsNum = toNumber(finPalletsNum);
          data.euroPalletsNum = toNumber(euroPalletsNum);
          data.maxWeight = toNumber(maxWeight);
          data.price = toNumber(price);

          try {
            switch(props.kind) {
              case 'offer':
                await post(ApiUrls.offers, data);
                history.push(Paths.offers);
                break;
              case 'request':
                await post(ApiUrls.requests, data);
                history.push(Paths.requests);
                break;
            }
          } catch(e) {
            alert(e);
          }
        }
      }}>

      {({
          values,
          errors,
          setValues,
          isSubmitting,
          submitForm
      }: FormikProps<Values>) => (
        <Form>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="xs" style={{padding: 0}} >
              <Grid container direction="column" spacing={2} >
                <Grid item>
                  <PortAutocomplete label="Port of loading"
                                    value={values.fromPort}
                                    error={errors.fromPort}
                                    onChange={port =>
                                      setValues({...values, fromPort: port})}
                  />
                </Grid>
                <Grid item>
                  <PortAutocomplete label="Port of discharge"
                                    value={values.toPort}
                                    error={errors.toPort}
                                    onChange={port =>
                                      setValues({...values, toPort: port})}
                  />
                </Grid>

                {/* Drop off */}
                <Grid item>
                  <CustomDivider/>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <DatePicker
                      inputFormat={dateInputFormat}
                      label="Drop-off start"
                      value={values.dropStart}
                      onChange={newValue =>
                        setValues({...values, dropStart: dropTime(newValue)})}
                      renderInput={(props) => (
                        <TextField {...props}
                                   fullWidth
                                   error={!!errors.dropStart}
                                   helperText={errors.dropStart}/>
                      )}
                      clearable
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      inputFormat={dateInputFormat}
                      label="Drop-off end"
                      value={values.dropEnd}
                      onChange={newValue =>
                        setValues({...values, dropEnd: dropTime(newValue)})}
                      renderInput={(props) => (
                        <TextField {...props}
                                   fullWidth
                                   error={!!errors.dropEnd}
                                   helperText={errors.dropEnd}
                        />
                      )}
                      clearable
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField label="Drop-off point"
                             fullWidth
                             value={values.dropPoint}
                             onChange={event =>
                               setValues({...values, dropPoint: event.target.value})}/>
                </Grid>

                {/* Pick up */}
                <Grid item>
                  <CustomDivider/>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <DatePicker
                      inputFormat={dateInputFormat}
                      label="Pick-up start"
                      value={values.pickStart}
                      onChange={newValue =>
                        setValues({...values, pickStart: dropTime(newValue)})}
                      renderInput={(props) => (
                        <TextField {...props}
                                   fullWidth
                                   error={!!errors.pickStart}
                                   helperText={errors.pickStart}
                        />
                      )}
                      clearable
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      inputFormat={dateInputFormat}
                      label="Pick-up end"
                      value={values.pickEnd}
                      onChange={newValue =>
                        setValues({...values, pickEnd: dropTime(newValue)})}
                      renderInput={(props) => (
                        <TextField {...props}
                                   fullWidth
                                   error={!!errors.pickEnd}
                                   helperText={errors.pickEnd}
                        />
                      )}
                      clearable
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField label="Pick-up point"
                             fullWidth
                             value={values.pickPoint}
                             onChange={event =>
                               setValues({...values, pickPoint: event.target.value})} />
                </Grid>

                {/* Container and pallets info */}
                <Grid item>
                  <CustomDivider/>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="container-select-label"
                                style={{
                                  background: 'white',
                                  paddingLeft: '5px',
                                  paddingRight: '5px',
                                }}>
                      Container
                    </InputLabel>
                    <Field
                      component={Select}
                      name="containerType"
                      inputProps={{
                        labelId: 'container-select-label',
                        label: 'Container',
                      }}
                    >
                      <MenuItem value={ContainerType._20ft} key={0}>{ContainerType._20ft}</MenuItem>
                      <MenuItem value={ContainerType._40ft} key={1}>{ContainerType._40ft}</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField label="EUR pallets number"
                             fullWidth
                             type="number"
                             value={values.euroPalletsNum}
                             error={!!errors.euroPalletsNum}
                             onChange={event =>
                               setValues({...values, euroPalletsNum: event.target.value})}
                             helperText={errors.euroPalletsNum}/>
                </Grid>
                <Grid item>
                  <TextField label="FIN pallets number"
                             fullWidth
                             type="number"
                             value={values.finPalletsNum}
                             error={!!errors.finPalletsNum}
                             onChange={event =>
                               setValues({...values, finPalletsNum: event.target.value})}
                             helperText={errors.finPalletsNum}
                  />
                </Grid>
                <Grid item>
                  <TextField label="Max weight, kg"
                             fullWidth
                             type="number"
                             value={values.maxWeight}
                             error={!!errors.maxWeight}
                             onChange={event =>
                               setValues({...values, maxWeight: event.target.value})}
                             helperText={errors.maxWeight}
                  />
                </Grid>
                { props.kind === 'offer' &&
                  <Grid item>
                    <TextField label="Price, USD"
                               fullWidth
                               type="number"
                               value={values.price}
                               error={!!errors.price}
                               onChange={event =>
                                 setValues({...values, price: event.target.value})}
                               helperText={errors.price}
                    />
                  </Grid>
                }
                <Grid item container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      {edit ? 'Save' : 'Create'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </LocalizationProvider>
        </Form>
      )}
    </Formik>
  )
}

interface Values {
  fromPort?: GetManyPortDto;
  toPort?: GetManyPortDto;

  dropPoint: string;
  dropStart: Date | null;
  dropEnd: Date | null;

  pickPoint: string;
  pickStart: Date | null;
  pickEnd: Date | null;

  containerType: ContainerType;

  price: string;
  finPalletsNum: string;
  euroPalletsNum: string;
  maxWeight: string;
}

export default OfferRequestForm;

const dropTime = (d: Date | null) => d ? new Date(d.toDateString()) : null;

const CustomDivider = () => <Divider sx={{bgcolor: 'primary.light'}}/>;
