import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import OfferRequestForm from '../../components/forms/OfferRequestForm';
import { GetOneOfferDto } from '../../dtos/offers/get-one-offer.dto';
import { ApiUrls } from '../../dtos/urls';
import { get } from '../../lib/requests';


const EditOfferPage = () => {
  const { id } = useParams<{id?: string}>();
  const [offer, setOffer] = useState<GetOneOfferDto | undefined>(undefined);

  useEffect(() => {
    get<GetOneOfferDto>(ApiUrls.offer(id ?? 0)).then(o => setOffer(o));
  }, []);

  return <>
    <Helmet>
      <title>Edit offer</title>
    </Helmet>

    {offer && <OfferRequestForm kind="offer" offer={offer}/>}
  </>
}

export default EditOfferPage;
