import React, {useEffect, useState} from 'react';
import {Formik, FormikErrors} from 'formik';
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {get, post} from "../../lib/requests";
import {ApiUrls} from "../../dtos/urls";
import {ConfirmedEmailDto} from "../../dtos/auth/confirm-email.dto";
import {useHistory} from "react-router-dom";
import {Paths} from "../../dtos/paths";
import LabeledCheckbox from "../../components/forms/fields/LabeledCheckbox";
import {useQuery} from "../../lib/useQuery";
import {Helmet} from "react-helmet";
import CountryAutocomplete from "../../components/forms/fields/CountryAutocomplete";
import {RegisterDto, RegisterResponseDto, RegistrationStatus} from "../../dtos/auth/register.dto";
import DialogButton from "../../components/forms/DialogButton";
import {TermsAndConditionsDialog} from "../../components/dialogs/TermsAndConditionsDialog";
import {DataProtectionAndPrivacyPolicyDialog} from "../../components/dialogs/DataProtectionAndPrivacyPolicyDialog";

interface Values {
  token: string;
  countryId: number;
  city: string;
  company: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordCheck: string;
  acceptedTerms: boolean;
  acceptedPrivacy: boolean;
  allowEmails: boolean;
  allowNewsletters: boolean;
}

const RegisterPage = () => {
  const query = useQuery();

  const initialValues: Values = {
    token: query.get('token') ?? '',
    countryId: 0,
    city: '',
    company: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordCheck: '',
    acceptedTerms: false,
    acceptedPrivacy: false,
    allowEmails: false,
    allowNewsletters: false,
  };

  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<RegistrationStatus | null>(null);
  const history = useHistory();

  useEffect(() => {
    get<ConfirmedEmailDto>(ApiUrls.Auth.confirmedEmail, {
      params: {
        token: query.get('token'),
      }})
      .then(data => {
        setEmail(data.email);
      })
      .catch(() => {
        setStatus(RegistrationStatus.BadToken);
      })
  }, [query]);

  const [termsOpen,   setTermsOpen]   = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>Registration</title>
      </Helmet>

      <Formik
        initialValues={initialValues}

        validate={(values) => {
          const errors: FormikErrors<Values> = {};
          const required = 'This field is required';

          if(!values.countryId) {
            errors.countryId = required;
          }

          if(!values.company.trim()) {
            errors.company = required;
          }

          validatePassword(values, errors);

          if(!values.acceptedTerms) {
            errors.acceptedTerms = required;
          }

          if(!values.acceptedPrivacy) {
            errors.acceptedPrivacy = required;
          }

          if(!values.allowEmails) {
            errors.allowEmails = required;
          }

          return errors;
        }}

        onSubmit={async (values, {setSubmitting}) => {
          setSubmitting(true);

          try {
            const {
              token,
              countryId,
              company,
              password,
              city,
              firstName,
              lastName,
              allowNewsletters,
            } = values;

            const response = await post<RegisterDto, RegisterResponseDto>(
              ApiUrls.Auth.register,
              {
                token,
                countryId,
                company,
                password,
                city,
                firstName,
                lastName,
                allowNewsletters,
              }
            );

            setStatus(response.status);

          } catch(e) {

          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
            values,
            errors,
            setValues,
            submitForm,
            isSubmitting,
          }) =>
          <Dialog open={true}>
            { status === null && values.token && email &&
              <>
                <DialogTitle>
                  Registration form
                </DialogTitle>

                <DialogContent>
                  <Stack>
                    <TextField label="Email"
                               type="email"
                               autoComplete="username"
                               value={email}
                               fullWidth
                               inputProps={{ readOnly: true }}
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <CountryAutocomplete value={values.countryId}
                                         onChange={newId => setValues({...values, countryId: newId})}
                                         label="Country"
                                         error={errors.countryId} />

                    <TextField label="City"
                               autoComplete="address-level2"
                               value={values.city}
                               error={!!errors.city}
                               onChange={event =>
                                 setValues({...values, city: event.target.value})}
                               helperText={errors.city}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <TextField label="Company"
                               autoComplete="organization"
                               value={values.company}
                               error={!!errors.company}
                               onChange={event =>
                                 setValues({...values, company: event.target.value})}
                               helperText={errors.company}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <TextField label="Contact person first name"
                               autoComplete="given-name"
                               value={values.firstName}
                               error={!!errors.firstName}
                               onChange={event =>
                                 setValues({...values, firstName: event.target.value})}
                               helperText={errors.firstName}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <TextField label="Contact person last name"
                               autoComplete="family-name"
                               value={values.lastName}
                               error={!!errors.lastName}
                               onChange={event =>
                                 setValues({...values, lastName: event.target.value})}
                               helperText={errors.lastName}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <TextField label="Password"
                               type="password"
                               autoComplete="new-password"
                               value={values.password}
                               error={!!errors.password}
                               onChange={event =>
                                 setValues({...values, password: event.target.value})}
                               helperText={errors.password}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <TextField label="Repeat password"
                               type="password"
                               autoComplete="new-password"
                               value={values.passwordCheck}
                               error={!!errors.passwordCheck}
                               onChange={event =>
                                 setValues({...values, passwordCheck: event.target.value})}
                               helperText={errors.passwordCheck}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <LabeledCheckbox label={<>Yes, I can confirm I have read and accept <a href="#" onClick={e => {
                                       e.preventDefault();
                                       setTermsOpen(true);
                                     }}>Terms and Conditions</a>.<br/></>}
                                     value={values.acceptedTerms}
                                     error={!!errors.acceptedTerms}
                                     onChange={(newValue) => setValues({...values, acceptedTerms: newValue})}/>

                    <LabeledCheckbox label={<>Yes, I can confirm I have read and accepted <a href="#" onClick={e => {
                                       e.preventDefault();
                                       setPrivacyOpen(true);
                                     }}>Data Protection and Privacy Policy</a>.</>}
                                     value={values.acceptedPrivacy}
                                     error={!!errors.acceptedPrivacy}
                                     onChange={(newValue) => setValues({...values, acceptedPrivacy: newValue})}/>

                    <LabeledCheckbox label="Yes, I would like to receive emails from Flexyfreight.com with service information and important updates."
                                     value={values.allowEmails}
                                     error={!!errors.allowEmails}
                                     onChange={(newValue) => setValues({...values, allowEmails: newValue})}/>

                    <LabeledCheckbox label="Yes, I would like to receive newsletters from Flexyfreight.com."
                                     value={values.allowNewsletters}
                                     onChange={(newValue) => setValues({...values, allowNewsletters: newValue})}/>
                  </Stack>
                </DialogContent>

                <DialogActions>
                  <DialogButton text="Confirm and complete"
                                loading={isSubmitting}
                                onClick={submitForm}/>
                </DialogActions>
              </>
            }

            { status === RegistrationStatus.Ok &&
              <>
                <DialogContent>
                  <Typography>
                    The registration process is successfully completed. Thank you!
                  </Typography>
                </DialogContent>

                <DialogActions>
                  <DialogButton text="Proceed to login"
                                onClick={() => history.push(Paths.Auth.login)}/>
                </DialogActions>
              </>
            }

            { status === RegistrationStatus.BadToken &&
            <>
                <DialogContent>
                    <Typography>
                      Bad token
                    </Typography>
                </DialogContent>
            </>
            }
          </Dialog>
        }
      </Formik>

      <TermsAndConditionsDialog
        open={termsOpen}
        onClose={() => setTermsOpen(false)} />

      <DataProtectionAndPrivacyPolicyDialog
        open={privacyOpen}
        onClose={() => setPrivacyOpen(false)} />

    </>
  )
}

export const validatePassword = (
  values: {
    password: string,
    passwordCheck: string,
  },
  errors: {
    password?: string,
    passwordCheck?: string,
  }
) => {

  const required = 'This field is required';
  const password = values.password.trim();
  const passwordCheck = values.passwordCheck.trim();

  if(!password) {
    errors.password = required;
  } else if(password.length < 6) {
    errors.password = 'Password should contain 6 characters or more';
  }

  if(!passwordCheck) {
    errors.passwordCheck = required;
  }

  if(password && passwordCheck && password !== passwordCheck) {
    errors.passwordCheck = 'Passwords should be the same';
  }
}

export default RegisterPage;
