import { IsEmail } from 'class-validator';
import {ConfirmedEmailDto} from "./confirm-email.dto";

export class RequestPasswordChangeDto {
  @IsEmail()
  email = '';
}

export enum RequestPasswordChangeStatus {
  Ok = 'ok',
  AlreadySent = 'already-sent',
  NotRegistered = 'not-registered',
  SendingError = 'sending-error',
}

export class RequestPasswordChangeResponseDto {
  status = RequestPasswordChangeStatus.Ok;
}

export class PasswordChangeEmailDto extends ConfirmedEmailDto {}
