import { prependApiPrefix } from '../dtos/urls';

export const credentials: RequestInit = {
  credentials: (window.location.hostname === 'localhost' ? 'include' : 'same-origin'),
  mode: 'cors',
};

//
let unauthorizedHandler = () => {}

export const setUnauthorizedHandler = (handler: () => void) => {
  unauthorizedHandler = handler;
}

export const handleUnauthorized = (response: Response) => {
  if(response.status === 401) {
    unauthorizedHandler();
  }
}

//
interface BaseConfig {
  params?: object;
}

const prepareUrl = (url: string, config?: BaseConfig): string => {
  url = prependApiPrefix(url);

  const params = new URLSearchParams();

  for(const [key, value] of Object.entries(config?.params ?? {})) {
    if(typeof value !== 'undefined') {
      params.set(key, value);
    }
  }

  const paramsString = params.toString();

  if(paramsString) {
    url += '?' + paramsString;
  }

  return url;
}

//
interface GetConfig extends BaseConfig {}

export const get = async <ResponseT,>(
  url: string,
  config?: GetConfig,
): Promise<ResponseT> => {

  const response = await fetch(prepareUrl(url, config), credentials);
  handleUnauthorized(response);
  return response.json();
}


//
interface PostConfig extends BaseConfig {}

export const post = async <RequestT = unknown, ResponseT = unknown>(
  url: string,
  body: RequestT,
  config?: PostConfig,
): Promise<ResponseT> => {

  const response = await fetch(prepareUrl(url, config), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    ...credentials,
  });

  handleUnauthorized(response);

  if(!response.ok) {
    const json = await response.json();
    throw new Error(json?.message)
  }

  const text = await response.text();

  if(!text) {
    return {} as ResponseT;
  }

  return JSON.parse(text);
}


//
interface PatchConfig extends BaseConfig {}

export const patch = async <RequestT = unknown, ResponseT = unknown>(
  url: string,
  body: RequestT,
  config?: PatchConfig,
): Promise<ResponseT> => {

  const response = await fetch(prepareUrl(url, config), {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    ...credentials,
  });

  handleUnauthorized(response);

  const text = await response.text();

  if(!text) {
    return {} as ResponseT;
  }

  return JSON.parse(text);
}

//
interface DeleteConfig extends BaseConfig {}

export const delete_ = async (
  url: string,
  config?: DeleteConfig,
): Promise<void> => {

  const response = await fetch(prepareUrl(url, config), {
    method: 'DELETE',
    ...credentials,
  });

  handleUnauthorized(response);

  if(!response.ok) {
    const json = await response.json();
    throw new Error(json?.message)
  }
}
