import {GetManyMatchedRequestDto} from "../../dtos/offers/get-many-offer.dto";
import {useReadyToTalk} from "./useReadyToTalk";
import {MatchedCaption} from "./MatchedCaption";
import {Box, IconButton, Link, Switch} from "@mui/material";
import React from "react";
import {Match} from "./Match";
import {daysBefore, matchExpanded} from "./utils";
import {copyToClipboard} from "../../lib/clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Line} from "./MatchedOffer";

export const MatchedRequest = (props: {
  match: GetManyMatchedRequestDto,
  index: number,
  offerPalletsNum: number,
}) => {
  const {match, offerPalletsNum} = props;
  const {ready, email, tryChange} = useReadyToTalk(match, 'offer');

  return (
    <Match
      key={match.matchId}
      caption={
        <MatchedCaption
          index={props.index}
          palletsNum={match.request.euroPalletsNum + match.request.finPalletsNum}
          totalPalletsNum={offerPalletsNum}
          match={match}
        />
      }
      details={
        <>
          <Line
            name={<>Company:</>}
            value={<b>{match.request.company.name}</b>}
          />
          <Line
            name={<>Days before shipping:</>}
            value={<b>{daysBefore(new Date(match.request.pickEnd))}</b>}
          />
          <Line
            name={
              <>
                <Box>Ready to talk:</Box>
                <Box><i>(your email will be shared)</i></Box>
              </>
            }
            value={
              <Switch
                checked={ready}
                onChange={e => tryChange(e.currentTarget.checked)}
              />
            }
          />

          {email &&
            <Line
              name={<>Mail to:</>}
              value={
                <>
                  <Link href={`mailto:${email}`}>{email}</Link>
                  <IconButton
                    sx={{marginLeft: '5px'}}
                    onClick={() => copyToClipboard(email).then(null)}
                  >
                    <ContentCopyIcon/>
                  </IconButton>
                </>
              }
            />
          }
        </>
      }
      expanded={matchExpanded(match)}
    />
  );
};
