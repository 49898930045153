import { IsEmail } from 'class-validator';

export class ConfirmEmailDto {
  @IsEmail()
  email = '';
}

export enum ConfirmEmailStatus {
  Ok = 'ok',
  SendingError = 'sending-error',
  AlreadySent = 'already-sent',
  AlreadyRegistered = 'already-registered',
}

export class ConfirmEmailResponseDto {
  status = ConfirmEmailStatus.Ok;
  minutesToWait?: number;
  message?: string;
}

export class ConfirmedEmailDto {
  email = '';
}
