import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { get } from '../../../lib/requests';
import { ApiUrls } from '../../../dtos/urls';
import { GetManyPortDto } from '../../../dtos/ports/get-many-port.dto';

interface PortAutocompleteProps {
  label: string;
  value?: GetManyPortDto;
  onChange?: (port?: GetManyPortDto) => void;
  error?: string;
}

const PortAutocomplete = (props: PortAutocompleteProps) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue] = useDebounce(inputValue, 500);
  const [options, setOptions] = useState<GetManyPortDto[]>(props.value ? [props.value] : [])

  useEffect(() => {
    if(debouncedValue.length >= 3) {
      get<GetManyPortDto[]>(ApiUrls.ports, {
        params: {
          search: debouncedValue,
          limit: 10,
        }
      }).then(ports => setOptions(ports))
    }
  }, [debouncedValue])

  return (
    <Autocomplete
      value={props.value}
      options={options}

      getOptionLabel={option => `${option.name} ${option.country} (${option.locode})`}

      isOptionEqualToValue={((option, value) => option && value ? option.id === value.id : false)}

      onInputChange={(event, value, reason) => {
        // console.log(`onInputChange: ${event} ${value} ${reason}`);

        if(reason === 'input') {
          setInputValue(value);
        }
      }}

      onChange={(event, value, reason, details) => {
        // console.log(`onChange: ${event} ${value} ${reason} ${details}`);
        props.onChange?.(value ?? undefined)
      }}

      renderInput={(params) => (
        <TextField {...params}
                   label={props.label}
                   error={!!props.error}
                   helperText={props.error}/>)}

      noOptionsText="Start typing"
    />
  );
}

export default PortAutocomplete;