import React, {useState} from 'react';
import {Formik, FormikErrors} from 'formik';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography} from "@mui/material";
import {post} from "../../lib/requests";
import {ApiUrls} from "../../dtos/urls";
import {useHistory} from "react-router-dom";
import {Paths} from "../../dtos/paths";
import {Helmet} from "react-helmet";
import DialogButton from "../../components/forms/DialogButton";
import {LoginDto} from "../../dtos/auth/login.dto";

const isEmail = require('is-email');

interface Values {
  email: string;
  password: string;
}

const LoginPage = () => {
  const initialValues: Values = {
    email: '',
    password: '',
  };

  const [loginFailed, setLoginFailed] = useState(false);
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Formik
        initialValues={initialValues}

        validate={(values) => {
          const errors: FormikErrors<Values> = {};

          if(!isEmail(values.email)) {
            errors.email = 'Should be a valid email';
          }

          if(!values.password.trim()) {
            errors.password = 'The field is required';
          }

          return errors;
        }}

        onSubmit={async (values, {setSubmitting}) => {
          setSubmitting(true);

          try {
            const { email, password } = values;

            await post<LoginDto>(ApiUrls.Auth.login, {
              email, password,
            });

            window.location.href = Paths.offers;

          } catch(e) {
            setLoginFailed(true);

          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
            values,
            errors,
            setValues,
            submitForm,
            resetForm,
            isSubmitting,
          }) =>
          <Dialog open={true}>
            <>
              <DialogTitle>
                  Please enter your email and password
              </DialogTitle>

              <DialogContent>
                  <Stack>
                    { loginFailed &&
                      <Typography sx={{color: 'red', marginBottom: 2}}>
                        Sorry, your password or email address is not correct.
                        Please try again, register or reset your password.
                      </Typography>
                    }

                    <TextField label="Email"
                               type="email"
                               autoComplete="email"
                               placeholder="Enter your email address"
                               value={values.email}
                               error={!!errors.email}
                               onChange={event =>
                                 setValues({...values, email: event.target.value})}
                               helperText={errors.email}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                    <TextField label="Password"
                               type="password"
                               autoComplete="current-password"
                               placeholder="Enter your password"
                               value={values.password}
                               error={!!errors.password}
                               onChange={event =>
                                 setValues({...values, password: event.target.value})}
                               helperText={errors.password}
                               fullWidth
                               sx={{marginTop: 1, marginBottom: 1}}/>

                  </Stack>
              </DialogContent>

              <DialogActions>
                <Stack sx={{width: '100%', marginLeft: 2}}>
                  <Grid container
                        justifyContent="flex-start"
                  >
                    <Grid item>
                      <DialogButton text="Register"
                                    color="secondary"
                                    onClick={() => history.push(Paths.Auth.confirmEmail)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container
                        justifyContent="space-between"
                  >
                    <Grid item>
                      <DialogButton text="Reset password"
                                    color="secondary"
                                    onClick={() => history.push(Paths.Auth.requestPasswordChange)}
                      />
                    </Grid>
                    <Grid item>
                      <DialogButton text="Login"
                                    loading={isSubmitting}
                                    onClick={submitForm}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </DialogActions>
            </>

          </Dialog>
        }
      </Formik>
    </>
  )
}

export default LoginPage;
