import { IsNotEmpty, Min } from 'class-validator';

export class RegisterDto {
  @IsNotEmpty()
  token = '';

  @Min(1)
  countryId = 0;

  @IsNotEmpty()
  company = '';

  @IsNotEmpty()
  password = '';

  city = '';
  firstName = '';
  lastName = '';
  allowNewsletters = false;
}

export enum RegistrationStatus {
  Ok = 'ok',
  BadToken = 'bad-token',
}

export class RegisterResponseDto {
  status = RegistrationStatus.Ok;
}
