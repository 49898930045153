export type OfferId = string | number;
export type RequestId = string | number;
export type MatchId = string | number;

export namespace ApiUrls {
  export const hello = '/';

  export const ports = '/ports';
  export const companies = '/companies';
  export const offers = '/offers';
  export const requests = '/requests';
  export const matches = '/matches';
  export const auth = '/auth';
  export const countries = '/countries';

  export const offer = (offerId: OfferId) => `${offers}/${offerId}`;
  export const request = (requestId: RequestId) => `${requests}/${requestId}`;
  export const match = (matchId: MatchId) => `${matches}/${matchId}`;

  export namespace Ports {
    export const update = `${ports}/update`;
  }

  export const company = '/company';

  export namespace Company {
    export const profile = `${company}/profile`;
  }

  export namespace Auth {
    export const login = `${auth}/login`;
    export const logout = `${auth}/logout`;
    export const profile = `${auth}/profile`;
    export const register = `${auth}/register`;
    export const confirmEmail = `${auth}/confirm-email`;
    export const confirmedEmail = `${auth}/confirmed-email`;
    export const changePassword = `${auth}/change-password`;
    export const requestPasswordChange = `${auth}/request-password-change`;
    export const passwordChangeEmail = `${auth}/password-change-email`;
  }
}

export const commonApiPrefix = '/api/v1';

export const prependApiPrefix = (url: string): string => {
  let prefix = '';

  if (window.location.hostname === 'localhost') {
    prefix = 'http://localhost:3000';
  }

  return `${prefix}${commonApiPrefix}${url}`;
};
